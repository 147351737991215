import React from "react";
import Layout from "../components/layout";
import { ipData } from "../utilities/tncdata";

import "./styles/tncs.css";

function IntellectualProperty(){
    return (
        <Layout>
            <h2 className="tncs-header"> {"Intellectual Property Policy"} </h2>
            <div className="tncs">
                <div> {`At SimplyFi, we respect the intellectual property rights of others, and we take the protection of our 
                    own intellectual property very seriously. This policy outlines the measures we take to protect our own intellectual 
                    property assets, including trademarks, copyrights, and trade secrets.`} 
                </div>

                <div> 
                    {ipData.map( ele => (
                        <div>
                            <h3> {ele.title}  </h3>
                            <div> {ele.description} </div>
                        </div>
                    ) )}
                    
                </div>  
            </div>
        </Layout>
    )
}

export default IntellectualProperty;